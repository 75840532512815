"use client"
import {useState} from "react";

export default function AddNewBlock({setAddBlockModalVisible, saveNewBlockData}) {

    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [link, setLink] = useState("");


    const handleSave = ()=>{
        saveNewBlockData(title, description, link);
    }


    return (

        <div className={"h-screen w-screen fixed flex items-center justify-center z-50 bg-blackTransparent"}>
            <div onClick={() => setAddBlockModalVisible(false)}
                 className={"absolute top-20 right-20 text-white text-2xl font-bold cursor-pointer"}>
                X
            </div>
            <div className={"bg-white rounded-2xl p-8 w-1/2"}>
                <div className="mb-4">
                    <label className="block text-battBlue font-bold">Title</label>
                    <input
                        type="text"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        className="mt-1 p-2 border rounded-md w-full"
                        placeholder="Enter title"
                    />
                </div>

                <div className="mb-4">
                    <label className="block text-battBlue font-bold">Description</label>
                    <textarea
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        className="mt-1 p-2 border rounded-md w-full"
                        placeholder="Enter description"
                    />
                </div>

                <div className="mb-4">
                    <label className="block text-battBlue font-bold">Link</label>
                    <input
                        type="text"
                        value={link}
                        onChange={(e) => setLink(e.target.value)}
                        className="mt-1 p-2 border rounded-md w-full"
                        placeholder="Enter link"
                    />
                </div>

                <div className="flex justify-end pt-2">
                    <button
                        onClick={()=>{setAddBlockModalVisible(false)}}
                        className="text-white font-bold py-1 px-4 bg-red rounded-md"
                    >
                        Cancel
                    </button>

                    <button
                        onClick={handleSave}
                        className="text-white font-bold ml-4 bg-battGreen py-1 px-4 rounded-md"
                    >
                        Save
                    </button>
                </div>
            </div>
        </div>

    )
}
