export default function HomeTabBlank({setAddBlockModalVisible}) {
    return (

        <div draggable onClick={() => {
            setAddBlockModalVisible(true)
        }}
             className={"box-border md:h-48 z-20 w-832 flex flex-col justify-between items-start rounded-lg overflow-hidden bg-battBlue p-4"}>
            <div className={"flex w-full h-full justify-center items-center"}>
                <div className={"text-white text-7xl"}>
                    +
                </div>
            </div>
        </div>
    )
}
