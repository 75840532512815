"use client"
import Navbar from "../components/navbar";
import BattSaasIcon from "../assets/BattSaasIcon.png";
import BattDaasIcon from "../assets/BattDaasIcon.png";
import HomeTab from "../components/HomeTab";
import HomeTabBlank from "../components/HomeTabBlank";
import {useEffect, useState} from "react";


export default function HomePage({keycloak}) {

    const [userName, setUserName] = useState("User");

    useEffect(() => {
        if (keycloak && keycloak.authenticated) {
            setUserName(keycloak.tokenParsed.preferred_username)
            localStorage.setItem("username", keycloak.tokenParsed.preferred_username)
        } else {
            setUserName(localStorage.getItem("username"))
        }
    }, [keycloak]);


    return (
        <main className={"overflow-x-hidden"}>
            <div className={"fixed z-0 h-screen w-screen"}>
                <img className={"h-full w-full bg-lightblue object-cover"} src={require("../assets/BattGenie City.png")}
                     alt={""}/>
            </div>
            <div className={"z-20 mb-10"}>
                <div className={"z-40"}>
                    <Navbar userName={userName}/>
                </div>
                <div className={"md:flex md:flex-col md:justify-center md:h-[90vh] md:gap-8"}>
                    <div className={"w-full flex md:flex-row flex-col gap-8 justify-center md:mt-0 mt-5 items-center"}>
                        {/*<HomeTab*/}
                        {/*    logo={BattSaasIcon}*/}
                        {/*    title={"SaaS - SIL"}*/}
                        {/*    type={""}*/}
                        {/*    text={"This powerful service empowers you to dive into the world of battery simulation"}*/}
                        {/*    linkUrl={process.env.REACT_APP_SIL_URL}*/}
                        {/*    linkUrlSim={process.env.REACT_APP_SIM_URL}/>*/}
                        {/*<HomeTab*/}
                        {/*    title={"SaaS - SIM"}*/}
                        {/*    type={""}*/}
                        {/*    logo={BattDaasIcon}*/}
                        {/*    linkUrlSim={""}*/}
                        {/*    text={"This powerful service empowers you to dive into the world of battery simulation"}*/}
                        {/*    linkUrl={process.env.REACT_APP_SIM_URL}/>*/}
                        {/*<HomeTab*/}
                        {/*    title={"-DaaS"}*/}
                        {/*    type={""}*/}
                        {/*    logo={BattDaasIcon}*/}
                        {/*    linkUrlSim={""}*/}
                        {/*    text={"BattDaaS powers BattStudio: Batch & real-time data ingestion, efficient storage, alerts, and visualizations."}*/}
                        {/*    linkUrl={process.env.REACT_APP_BATT_DAAS_URL}/>*/}
                        <HomeTab
                            type={""}
                            logo={BattSaasIcon}
                            title={"-Mon"}
                            linkUrlSim={""}
                            text={"Monitors BattDB, alerts on anomalies, and offers visualizations for real-time insights."}
                            linkUrl={process.env.REACT_APP_BATT_MON_URL}/>
                        <HomeTab
                            type={""}
                            logo={BattSaasIcon}
                            title={"-ETL"}
                            linkUrlSim={""}
                            text={"Batch Data Ingestion - Seamlessly import batch data for in-depth analysis."}
                            linkUrl={process.env.REACT_APP_BATT_ETL_URL}/>

                    </div>
                    <div
                        className={"md:w-full flex gap-8 justify-center md:flex-row flex-col md:mt-0 mt-5 items-center"}>
                        <HomeTab
                            type={""}
                            logo={BattSaasIcon}
                            title={"ETL-RT"}
                            linkUrlSim={""}
                            text={"Real-Time Data Ingestion - Experience real-time insights through instant data ingestion."}
                            linkUrl={process.env.REACT_APP_BATT_RT_URL}/>
                        <HomeTabBlank/>
                    </div>
                </div>
            </div>
        </main>
    )
}
