import Logo from "../assets/Brand mark.png";
import LogoA from "../assets/Word mark.png";
import Avatar from "../assets/3d_avatar_13.png";
import axios from "axios";
import {IoDocumentTextOutline} from "react-icons/io5";

export default function Navbar({userName, setAddBlockModalVisible}) {

    const goToDocs = () => {
        window.open(process.env.REACT_APP_BATTSTUDIO_DOCUMENTATION_URL)
    }

    const delete_cookie = function () {
        try {
            let realmName = process.env.REACT_APP_BATTKEY_REALM;
            let battKeyHost = process.env.REACT_APP_BATTKEY_URL;
            const logoutUrl = `${battKeyHost}/realms/${realmName}/protocol/openid-connect/logout`;
            axios.post(logoutUrl, {
                'client_id': process.env.REACT_APP_BATTKEY_CLIENTID,
                'refresh_token': localStorage.getItem("refreshToken")
            }, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }).then(() => {
                window.location.reload();
            })
        } catch (error) {
            console.log(error)
        }
    };

    const goToBattGenie = () => {
        window.open("https://battgenie.life/");
    }


    return (
        <div className={"relative w-screen md:h-14 bg-lightestBlue py-2 pl-4    px-2 md:px-4 md:flex justify-between items-center"}>
            <div className={"md:w-auto w-screen flex justify-between items-center"}>
                <div className={"flex items-center"}>
                    <img className={"md:h-8 md:w-8 h-5 w-5"} src={Logo} alt={'Battgenie logo'}/>
                    <img className={"md:h-5 md:pl-3 h-3 pl-2"} src={LogoA} alt={'Battgenie Title'}/>
                </div>
                <div onClick={goToDocs} className={"flex items-center mr-8 cursor-pointer md:hidden"}>
                    <IoDocumentTextOutline size={22} color={"#222961"}/>
                    <div className={"pl-1"}>Documentation</div>
                </div>
            </div>
            <div className={"md:flex mt-2 md:mt-0"}>
                <div onClick={setAddBlockModalVisible} className={"md:flex items-center mr-8 cursor-pointer hidden"}>
                    <div className={"pl-1"}>Add Shortcut</div>
                </div>
                <div onClick={goToDocs} className={"md:flex items-center mr-8 cursor-pointer hidden"}>
                    <IoDocumentTextOutline size={22} color={"#222961"}/>
                    <div className={"pl-1"}>Documentation</div>
                </div>
                <div className={"flex md:w-auto w-full justify-between bg-red-500"}>
                    <div className={"flex items-center"}>
                        <div className={"mr-2"}>Hi, {userName}</div>
                        <img className={"h-8 w-auto"} src={Avatar} alt={""}/>
                    </div>
                    <div onClick={delete_cookie} className={"px-4 py-1 bg-battGreen ml-6 rounded-2xl cursor-pointer"}>
                        Logout
                    </div>
                </div>
            </div>
        </div>
    )
}
