"use client"
import Navbar from "../components/navbar";
import BattSaasIcon from "../assets/BattSaasIcon.png";
import HomeTab from "../components/HomeTab";
import {useEffect, useState} from "react";
import AddNewBlock from "../components/AddNewBlock";
import DropArea from "../components/DropArea";
import React from "react";
import HomeTabBlank from "../components/HomeTabBlank";
import axios from "axios";


export default function HomePage({keycloak}) {

    const [userName, setUserName] = useState("User");
    const [activeCard, setActiveCard] = useState(null);
    const [addBlockModalVisible, setAddBlockModalVisible] = useState(false);

    const getHomePageData = async () => {
        try {
            const data = localStorage.getItem("homePageTiles");
            const latestId = localStorage.getItem("homePageTileId");
            if (data){
                setHomeTabData(JSON.parse(data))
            }
            if(!latestId){
                localStorage.setItem("homePageTileId", "3")
            }
        }catch (e){
            console.log(e)
        }
    };

    const [homeTabData, setHomeTabData] = useState([
        {
            id: 1,
            type: "",
            logo: BattSaasIcon,
            title: "-Mon",
            linkUrlSim: "",
            text: "Monitors BattDB, alerts on anomalies, and offers visualizations for real-time insights.",
            linkUrl: process.env.REACT_APP_BATT_MON_URL
        },
        {
            id: 2,
            type: "",
            logo: BattSaasIcon,
            title: "-ETL",
            linkUrlSim: "",
            text: "Batch Data Ingestion - Seamlessly import batch data for in-depth analysis.",
            linkUrl: process.env.REACT_APP_BATT_ETL_URL
        },
        {
            id: 3,
            type: "",
            logo: BattSaasIcon,
            title: "ETL-RT",
            linkUrlSim: "",
            text: "Real-Time Data Ingestion - Experience real-time insights through instant data ingestion.",
            linkUrl: process.env.REACT_APP_BATT_RT_URL
        }
    ])

    const battStudioLogout= ()=>{
        try {
            let realmName = process.env.REACT_APP_BATTKEY_REALM;
            let battKeyHost = process.env.REACT_APP_BATTKEY_URL;
            const logoutUrl = `${battKeyHost}/realms/${realmName}/protocol/openid-connect/logout`;
            axios.post(logoutUrl, {
                'client_id': process.env.REACT_APP_BATTKEY_CLIENTID,
                'refresh_token': localStorage.getItem("refreshToken")
            }, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }).then(() => {
                window.location.reload();
            })
        } catch (error) {
            console.log(error)
        }
    }


    const removeCard = (id: number) => {
        let data = [...homeTabData];
        data = data.filter(item => {
            return item.id !== id;
        });
        setHomeTabData(data);
        localStorage.setItem("homePageTiles" , JSON.stringify(data))
    }

    useEffect(()=>{
        getHomePageData();
    },[])

    useEffect(() => {
        if (keycloak && keycloak.authenticated) {
            setUserName(keycloak.tokenParsed.preferred_username)
            localStorage.setItem("username", keycloak.tokenParsed.preferred_username)
        } else {
            setUserName(localStorage.getItem("username"))
        }
    }, [keycloak]);


    const onDrop = (index) => {
        let active = null;
        homeTabData.forEach((item)=>{
            if(item.id === activeCard){
                active = item;
            }
        })
        let data = [...homeTabData];
        data = data.filter((item)=>{
            return item.id !== activeCard
        })
        data.splice(index, 0, active);
        setHomeTabData(data);
    }

    const saveNewBlockData = (title: string, description: string, link: string) => {
        const id = JSON.parse(localStorage.getItem("homePageTileId"));
        const data = {
            id: id+1,
            type: "",
            logo: BattSaasIcon,
            title: title,
            linkUrlSim: "",
            text: description,
            linkUrl: link
        }
        const newData = [data].concat(homeTabData);
        setHomeTabData(newData);
        setAddBlockModalVisible(false);
        localStorage.setItem("homePageTileId", id+1);
        localStorage.setItem("homePageTiles" , JSON.stringify(newData));
    }

    const addNewLinkToDB = () => {

    }


    return (
        <main className={"overflow-x-hidden"}>
            {addBlockModalVisible ? <AddNewBlock saveNewBlockData={saveNewBlockData}
                                                 setAddBlockModalVisible={setAddBlockModalVisible}/> : null}
            <div className={"fixed z-0 h-screen w-screen"}>
                <img className={"h-full w-full bg-lightblue object-cover"} src={require("../assets/BattGenie City.png")}
                     alt={""}/>
            </div>
            <div className={"z-20 mb-10 min-h-[80vh] w-screen"}>
                <div className={"z-40"}>
                    <Navbar setAddBlockModalVisible={setAddBlockModalVisible} userName={userName}/>
                </div>
                <div className={"flex justify-center items-center w-full min-h-[80vh] h-full"}>
                    <div
                        className={homeTabData.length > 6 ? "grid md:grid-cols-4 md:gap-x-8" : "md:grid md:grid-cols-3 md:gap-x-8"}>
                        {homeTabData.map((item, index) => {
                            return (
                                <div className={"flex flex-col justify-center items-center"} key={item.id}>
                                    <DropArea
                                        index={index}
                                        onDrop={onDrop}/>
                                    <HomeTab
                                        removeCard={removeCard}
                                        id={item.id}
                                        setActiveCard={setActiveCard}
                                        type={item.type}
                                        logo={item.logo}
                                        title={item.title}
                                        linkUrlSim={item.linkUrlSim}
                                        text={item.text}
                                        linkUrl={item.linkUrl}/>
                                </div>
                            )
                        })}
                        {/*<div className={""}>*/}
                        <HomeTabBlank setAddBlockModalVisible={setAddBlockModalVisible}/>
                        {/*</div>*/}
                    </div>
                </div>
            </div>
            <div className="dropdown-wrapper">
                <div className="battstudio-button">
                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M0 8.88889C0 3.97969 3.97969 0 8.88889 0H31.1111C36.0203 0 40 3.97969 40 8.88889V31.1111C40 36.0203 36.0203 40 31.1111 40H8.88889C3.97969 40 0 36.0203 0 31.1111V8.88889Z"
                            fill="#222961"/>
                        <path
                            d="M31.1128 17.7775L22.224 31.1108H17.7795V22.2219H8.89062L17.7795 8.88861H22.224V17.7775H31.1128Z"
                            fill="#01DEBD"/>
                        <path d="M26.6686 4.44427H22.2241V8.88872H26.6686V4.44427Z" fill="#01DEBD"/>
                        <path d="M17.7804 31.111H13.3359V35.5555H17.7804V31.111Z" fill="#01DEBD"/>
                        <path d="M19.3351 31.111C18.4773 31.111 17.7795 31.8088 17.7795 32.6666V31.111H19.3351Z"
                              fill="#01DEBD"/>
                        <path d="M17.7777 29.5554V31.1109H16.2222C17.0799 31.1109 17.7777 30.4131 17.7777 29.5554Z"
                              fill="#01DEBD"/>
                        <path d="M23.7797 8.88861C22.9219 8.88861 22.2241 9.58639 22.2241 10.4442V8.88861H23.7797Z"
                              fill="#01DEBD"/>
                        <path d="M22.225 7.33319V8.88875H20.6694C21.5272 8.88875 22.225 8.19097 22.225 7.33319Z"
                              fill="#01DEBD"/>
                    </svg>
                </div>
                <div className="dropdown-content">
                    <a onClick={() => {
                        window.open(`https://${process.env.REACT_APP_COOKIE_DOMAIN}`, '_self')
                    }}>Home</a>
                    <div style={{height: '1px', width: '100%', background: 'lightgray'}}></div>
                    <a onClick={() => {
                        battStudioLogout()
                    }} href="">Logout</a>
                </div>
            </div>
        </main>
    )
}
