"use client"
import {useState} from "react";
import "./components.css";

export default function DropArea({onDrop, index}) {

    const [showDrop, setShowDrop] = useState(false);

    return (
        <div onDrop={(e)=>{
            onDrop(index)
            setShowDrop(false);
        }}
             onDragOver={(e)=>{
                 e.stopPropagation();
                 e.preventDefault()}
        }
             onDragEnter={(e)=>{
                 setShowDrop(true)
             }} onDragLeave={(e)=>{
                 setShowDrop(false)
             }}
             className={showDrop?"box-border cursor-pointer z-20 w-832 flex flex-col justify-between items-start rounded-lg overflow-hidden bg-battBlue p-4 drop_area":
                 "hide_drop box-border cursor-pointer  z-20 w-832 flex flex-col justify-between items-start rounded-lg overflow-hidden bg-battBlue p-4"}
        >
        </div>
    )
}
