// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.drop_area{
    opacity: 1;
    height: 2rem;
    transition: all 1s ease-in-out;
    margin: 5px 0;
}

.hide_drop{
    height: 2rem;
    margin: 5px 0;
    opacity: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/components.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,YAAY;IACZ,8BAA8B;IAC9B,aAAa;AACjB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,UAAU;AACd","sourcesContent":[".drop_area{\n    opacity: 1;\n    height: 2rem;\n    transition: all 1s ease-in-out;\n    margin: 5px 0;\n}\n\n.hide_drop{\n    height: 2rem;\n    margin: 5px 0;\n    opacity: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
