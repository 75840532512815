import { HiOutlineDotsVertical } from "react-icons/hi";
import {useState, useEffect, useRef} from "react";

const DropDown = ({ onOutsideClick, removeCard })=>{
    const divRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            // Check if the click is outside the div
            if (divRef.current && !divRef.current.contains(event.target)) {
                onOutsideClick(); // Call the provided function if clicked outside
            }
        };

        // Add event listener to the document
        document.addEventListener("mousedown", handleClickOutside);

        // Cleanup event listener on component unmount
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [onOutsideClick]);


    return(
        <div ref={divRef}  className={"bg-white absolute z-30 right-0 top-5 px-2 rounded-sm"}>
            <div onClick={removeCard}>
                Remove
            </div>
        </div>
    )
}

export default function HomeTab({id, setActiveCard, linkUrl, logo, text, title, type, linkUrlSim, removeCard}) {

    const [showDropdown, setShowDropdown] = useState(false);

    const openDropdown = ()=>{
        setShowDropdown(true);
    }

    const closeDropDown = ()=>{
        setShowDropdown(false);
    }



    return (
        <div  draggable onDragEnd={()=>{setActiveCard(null)}} onDragStart={()=>{setActiveCard(id)}}
            className={"box-border md:h-48 z-20 w-832 flex flex-col justify-between items-start rounded-lg overflow-hidden bg-battBlue p-4"}>
            <div className={"flex w-full justify-between items-center"}>
                <div className={"flex"}>
                    <img className={"h-7 w-auto"} src={logo} alt={""}/>
                    <div className={"flex pl-2"}>
                        <div className={"text-white text-xl tracking-wide"}>
                            Batt
                        </div>
                        <div className={"text-battGreen  text-xl  tracking-wide"}>
                            {title}
                        </div>
                    </div>
                </div>
                <div className={"cursor-pointer relative"}>
                    <HiOutlineDotsVertical onClick={openDropdown} color={"white"} />
                    {showDropdown?<DropDown removeCard={()=>{removeCard(id)}} onOutsideClick={closeDropDown} />:null}
                </div>
            </div>
            <div className={"py-4 text-sm  text-white leading-5 text-left"}>{text}</div>
            {type == "sil" ? <div className={"flex"}>
                <div onClick={() => {
                    window.open(linkUrl, "_self")
                }} className={"bg-battGreen py-2 flex  items-center  px-6 rounded-2xl"}>
                    <div className={"text-xs"}>
                        SIL
                    </div>
                    <img className={"h-5 pl-2"} src={require("../assets/right.png")}/>
                </div>
                <div onClick={()=>{window.open(linkUrlSim, "_self")}} className={"bg-battGreen ml-3 py-2 flex  items-center  px-6 rounded-2xl"}>
                    <div className={"text-xs"}>
                        SIM
                    </div>
                    <img className={"h-5 pl-2"} src={require("../assets/right.png")}/>
                </div>
            </div>:<div onClick={()=>{window.open(linkUrl, "_self")}} className={"bg-battGreen py-2 flex  items-center  px-6 rounded-2xl"}>
                <div className={"text-xs  cursor-pointer"}>
                    Get Started
                </div>
                <img className={"h-5 pl-2"} src={require("../assets/right.png")}/>
            </div>}
        </div>
    )
}
