import BattGenieCity from "../assets/BattGenie City.png";
import Logo from "../assets/Brand mark.png";
import Title from "../assets/Word mark.png";
import Checkbox from "../assets/checkbox.png";


export default function Login() {

    const handleClick = (e: any) => {
        e.preventDefault()
        // router.push("/home");
    }

    return (
        <main>
            <div className={"flex h-screen"}>
                <div className={"w-2/5 h-full p-20 flex flex-col"}>
                    <div className={"flex items-center"}>
                        <img className={"h-10 w-10"} src={Logo} alt={""}/>
                        <img className={"h-5 ml-4 w-auto"} src={Title} alt={""}/>
                    </div>
                    <div>
                        <div className={"text-bg-green mt-12 text-xl font-bold"}>
                            Magical, yet scientific!
                        </div>
                        <div className={"font-bg-blue text-sm mt-4"}>
                            Here’s how we created a super-smart, shockingly efficient way to charge and manage batteries
                        </div>
                        <div className={"font-bg-blue text-sm mt-12"}>
                            Welcome, Please login to your account
                        </div>
                    </div>
                    <div className={"mt-4"}>
                        <div
                            className={"focus-within:border-l-4 focus-within:border-l-bg-electric-blue border-2 border-lightblue px-1 py-1 pb-1"}>
                            <div className={"text-bg-gray"}>Email Address</div>
                            <input
                                className="py-1 text-bg-electric-blue focus:outline-none focus:shadow-outline"
                                id="password" type="text" placeholder="john@exmaple.com"/>
                        </div>
                        <div
                            className={"focus-within:border-l-4 focus-within:border-l-bg-electric-blue border-b-2 border-l-2 border-r-2 border-lightblue p-2 pb-1"}>
                            <div className={"text-bg-gray"}>Password</div>
                            <input
                                className="py-2 text-bg-electric-blue focus:outline-none focus:shadow-outline"
                                id="password" type="password" placeholder="**************"/>
                        </div>
                    </div>
                    <div className={"flex justify-between items-center mt-4"}>
                        <div className={"flex items-center"}>
                            <img className={"w-5 mr-1 "} src={Checkbox} alt={""}/>
                            <div className={"text-bg-gray"}>
                                Remember Me
                            </div>
                        </div>
                        <div className={"text-bg-gray cursor-pointer"}>
                            Forgot Password
                        </div>
                    </div>
                    <div className={"flex mt-8"}>
                        <div onClick={handleClick}
                             className={"bg-bg-green py-1.5 cursor-pointer px-6 rounded-3xl mr-4"}>
                            <div className={"text-sm text-bg-blue"}>Login</div>
                        </div>
                        <div className={"border border-lightblue cursor-pointer py-1.5 px-6 rounded-3xl"}>
                            <div className={"text-sm text-bg-blue"}>Sign Up</div>
                        </div>
                    </div>
                </div>
                <div className={"w-3/5 h-full"}>
                    <img className={"h-screen w-full"} src={BattGenieCity} alt={""}/>
                </div>
            </div>
        </main>
    )
}
